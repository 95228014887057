document.addEventListener("DOMContentLoaded", () => {
	document.querySelectorAll(".content-sticky-nav").forEach((section) => {
		const navItems = section.querySelectorAll(".sticky-nav li");
		const contentBlocks = section.querySelectorAll(".sticky-contents > div");

		if (!navItems.length || !contentBlocks.length) return;

		const observer = new IntersectionObserver(
			(entries) => {
				let maxVisibility = 0;
				let mostCentered = null;

				entries.forEach((entry) => {
					const rect = entry.boundingClientRect;
					const visibility = rect.height * entry.intersectionRatio;
					const centerOffset = Math.abs(rect.top + rect.height / 2 - window.innerHeight / 2);
					const score = visibility - centerOffset; // Prioritize visibility but also closeness to center

					if (score > maxVisibility) {
						maxVisibility = score;
						mostCentered = entry.target;
					}
				});

				if (mostCentered) {
					const id = mostCentered.getAttribute("id");
					navItems.forEach((item) => {
						item.classList.toggle("active", item.dataset.id === id.split("-").pop());
					});
				}
			},
			{
				root: null,
				rootMargin: "0px",
				threshold: [0.1, 0.5, 1.0],
			}
		);

		contentBlocks.forEach((block) => observer.observe(block));
	});
});
