document.addEventListener('DOMContentLoaded', function () {
	const rows = document.querySelectorAll('.list-group-item-studies');

	const cityCheckboxes = document.querySelectorAll('.study-filter-city');
	const countryCheckboxes = document.querySelectorAll('.study-filter-country');
	const degreeCheckboxes = document.querySelectorAll('.study-filter-degree');
	const tagsWrapper = document.querySelector('.col-tags');

	function filterRows() {
		rows.forEach((row) => {
			const rowCampus = row.getAttribute('data-campus').split(',');
			const rowCountry = row.getAttribute('data-country');
			const rowDegree = row.getAttribute('data-degree');

			let showRow = true;

			const selectedCities = Array.from(cityCheckboxes).filter(checkbox => checkbox.checked).map(checkbox => checkbox.value);
			if (selectedCities.length > 0 && !selectedCities.some(cityId => rowCampus.includes(cityId))) {
				showRow = false;
			}

			const selectedCountries = Array.from(countryCheckboxes).filter(checkbox => checkbox.checked).map(checkbox => checkbox.value);
			if (selectedCountries.length > 0 && !selectedCountries.includes(rowCountry)) {
				showRow = false;
			}

			const selectedDegrees = Array.from(degreeCheckboxes).filter(checkbox => checkbox.checked).map(checkbox => checkbox.value);
			if (selectedDegrees.length > 0 && !selectedDegrees.includes(rowDegree)) {
				showRow = false;
			}

			row.style.display = showRow ? '' : 'none';
		});

		let visibleRows = Array.from(rows).filter(row => row.style.display !== 'none');

		visibleRows.forEach((row, index) => {
			if ((index + 1) % 2 === 0) {
				row.classList.add('new-striped-background');
			} else {
				row.classList.remove('new-striped-background');
			}
		});

		if (tagsWrapper) {
			tagsWrapper.innerHTML = '';
			[...cityCheckboxes, ...countryCheckboxes, ...degreeCheckboxes].forEach(cb => {
				if (cb.checked) {
					const label = cb.nextElementSibling.innerHTML;
					const newTag = document.createElement('div');
					newTag.classList.add('tag');
					newTag.innerText = label;
					const closeButton = document.createElement('a');
					closeButton.classList.add('remove-tag');
					closeButton.dataset.for = cb.id;
					closeButton.role = 'button';
					closeButton.href = '#';
					closeButton.innerHTML = '<svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14.3995 14.273L14.0459 14.6266C13.8507 14.8218 13.5341 14.8218 13.3388 14.6266L9.27298 10.5607L5.20708 14.6265C5.01186 14.8218 4.69527 14.8218 4.5 14.6265L4.14645 14.273C3.95118 14.0777 3.95118 13.7612 4.14645 13.5659L8.21233 9.50003L4.14645 5.43413C3.95118 5.23891 3.95118 4.92232 4.14645 4.72706L4.5 4.3735C4.69526 4.17824 5.01186 4.17824 5.20708 4.3735L9.27298 8.43938L13.3388 4.3735C13.5341 4.17824 13.8507 4.17824 14.0459 4.3735L14.3995 4.72705C14.5948 4.92231 14.5948 5.23891 14.3995 5.43413L10.3336 9.50003L14.3995 13.5659C14.5948 13.7612 14.5948 14.0777 14.3995 14.273Z" fill="black"/></svg>';
					newTag.insertAdjacentElement('beforeend', closeButton);
					tagsWrapper.appendChild(newTag);
				}
			});
		}
	}

	cityCheckboxes.forEach(checkbox => {
		checkbox.addEventListener('change', filterRows);
	});
	countryCheckboxes.forEach(checkbox => {
		checkbox.addEventListener('change', filterRows);
	});
	degreeCheckboxes.forEach(checkbox => {
		checkbox.addEventListener('change', filterRows);
	});

	if (tagsWrapper) {
		tagsWrapper.addEventListener('click', e => {
			e.preventDefault();
			const removeTagLink = e.target.closest('.remove-tag');
			if (removeTagLink) {
				const checkbox = [...cityCheckboxes, ...countryCheckboxes, ...degreeCheckboxes].find(cb => cb.id === removeTagLink.dataset.for);
				if (checkbox) {
					checkbox.checked = false;
					filterRows();
				}
			}
		});
	}

	filterRows();
});
